import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/login',
  name: 'login',
  component: () => import('@/views/login/index.vue')
}, {
  path: '/',
  name: 'loayout',
  redirect: '/progress',
  component: () => import('@/components/layout/index.vue'),
  children: [
    {
      path: '/progress',
      component: () => import('@/views/progress/index.vue')
    },
    {
      path: '/order',
      component: () => import('@/views/order/index.vue')
    },
    {
      path: '/outOrder',
      component: () => import('@/views/outOrder/index.vue')
    },
    {
      path: '/user',
      component: () => import('@/views/user/index.vue')
    },
    {
      path: '/addOrder',
      component: () => import('@/views/order/addOrder.vue')
    },
    {
      path: '/adduser',
      component: () => import('@/views/user/addUser.vue')
    }
  ]
}
]

const router = new VueRouter({
  routes
})

// 路由守卫
// to 即将要进入的地址
// from 当前导航正要离开的路由
// next 放行
router.beforeEach((to, from, next) => {
  const user = JSON.parse(window.localStorage.getItem('user'))
  if (to.path !== '/login') {
    if (!user) {
      next('/login')
    } else next()
  } else {
    next()
  }
})
export default router
