<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  mounted() {
    console.log('app');
    const user = JSON.parse(window.localStorage.getItem("user"));
    if (user) {
      this.login(user);
    }
  },
  methods: {
    async login(user) {
      let response = await this.$request.post("/existPhone", { tel: user.tel });
      if (!response.data.res) {
        this.$message({
          message: "账号不存在",
          type: "error",
        });
        window.localStorage.removeItem("user");
        this.$router.push({ path: "/login" });
      }
    },
  },
};
</script>

<style>
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}
ul,
li,
a {
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;
}
#app {
  height: 100%;
}
</style>
